import React, { useMemo } from "react";
import _ from "lodash";
import { unstable_batchedUpdates } from "react-dom";
import { NavigationProp } from "@react-navigation/native";
import { Title16 } from "@stylesheets";
import {
  Calendar,
  EpointageCommentModal,
  EpointageComments,
  EpointageDownloadModal,
  EpointageMenu,
  EpointageSchedules,
  EpointageScreenWithFooter,
  LocalLoader,
  NoPointageAccount,
} from "@atomic";
import { I18n } from "react-redux-i18n";
import { View, StyleSheet, Pressable } from "react-native";
import { useWindowSize } from "@hooks";
import { SelectSchoolSVG, PointageUnavailableSVG, Download } from "@assets";
import { GlobalState, LoaderActions, store } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import {
  EpointageService,
  EpointageSchool,
  ComputedClassroom,
  Scheduler,
  EpointageSite,
} from "@foodi/core";
import { SchoolViewModel } from "../viewmodels/SchoolViewModel";
import { DropDownSelect } from "../components";
import {
  EpointageActions,
  EpointageThunks,
  SchedulerViewModel,
  SchedulersWithDynamicKeys,
  AuthActions,
} from "@modules";
import { Colors } from "@constants";
import { EpointageClassrooms } from "@atomic";
import moment from "moment";

interface IProps {
  navigation: NavigationProp<any>;
}

export interface IListItem {
  id: string;
  name: string;
}

export const Homepage: React.FC<IProps> = ({ navigation }) => {
  const localLanguage = useSelector((state: GlobalState) => state.i18n.locale);
  moment.locale(localLanguage);
  const initialDate = moment();

  const isLoading = useSelector((state: GlobalState) => state.loader.loading);
  const havePointageAccount = useSelector(
    (state: GlobalState) => state?.auth?.havePointageAccount
  );
  const [selectedDate, setSelectedDate] = React.useState(initialDate);

  const [ePointServicesList, setEPointServicesList] = React.useState<
    IListItem[]
  >([]);
  const [ePointSchoolsList, setEPointSchoolList] = React.useState<IListItem[]>(
    []
  );
  const [sitesList, setSitesList] = React.useState<IListItem[]>(
    []
  );
  const [ePointClassesList, setEPointClassesList] = React.useState<IListItem[]>(
    []
  );
  const [scheduler, setScheduler] = React.useState<Scheduler>();
  const [
    mappedScheduler,
    setMappedScheduler,
  ] = React.useState<SchedulersWithDynamicKeys>();
  const [commentsByClassroom, setCommentsByClassroom] = React.useState<
    ComputedClassroom[]
  >();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = React.useState<boolean>(
    false
  );

  const [selectedSchool, setSelectedSchool] = React.useState<
    IListItem | undefined
  >();
  const [selectedClass, setSelectedClass] = React.useState<
    IListItem | undefined
  >();
  const [selectedService, setSelectedService] = React.useState<
    IListItem | undefined
  >();
  const [selectedSite, setSelectedSite] = React.useState<IListItem | undefined>(getLocalStorageItemParsed('siteId') || undefined);

  const [sitesDataLoaded, setSitesDataLoaded] = React.useState(false);
  const [schoolsDataLoaded, setSchoolsDataLoaded] = React.useState(false);
  const [classesDataLoaded, setClassesDataLoaded] = React.useState(false);
  const [servicesDataLoaded, setServicesDataLoaded] = React.useState(false);

  const resetDataLoaded = () => {
    setSitesDataLoaded(false);
    setSchoolsDataLoaded(false);
    setClassesDataLoaded(false);
    setServicesDataLoaded(false);
  };

  const dispatch = useDispatch();

  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );

  const schoolService = new SchoolViewModel(dispatch);

  const epointageRequests = async (siteId?: string) => {
    resetDataLoaded();

    const EpointageServiceResponse = await dispatch(
      EpointageThunks.getEpointageServices({
        siteId: siteId ?? selectedSite!.id,
        published: "true",
      })
    );

    const servicesResponse =
      _.get(EpointageServiceResponse, "epointageServices") ?? [];

    const serviceList: IListItem[] = servicesResponse.map(
      (service: EpointageService): IListItem => {
        return { id: service.id.toString(), name: service.name };
      }
    );

    dispatch(EpointageActions.setServices(serviceList));
    setEPointServicesList(serviceList);

    if (!selectedService) {
      setSelectedService(serviceList[0]);
    }

    const schoolsResponse: EpointageSchool[] =
      (await schoolService.getSchoolInfo(siteId ?? selectedSite!.id)) ?? [];

    const schoolsList = schoolsResponse.map(
      (school: EpointageSchool): IListItem => {
        return { id: school.schoolId.toString(), name: school.name };
      }
    );

    dispatch(EpointageActions.setSchools(schoolsList));
    setEPointSchoolList(schoolsList);

    if (selectedSchool) {
      const schedulerResult = await schedulerVM.getSchedulerInfo(
        siteId ?? selectedSite!.id,
        selectedSchool.id,
        selectedDate.format("YYYY-MM-DD")
      );

      const classesList = _.get(schedulerResult, "school.classrooms") ?? [];

      const ClassList = await classesList.map(
        (service: ComputedClassroom): IListItem => {
          return { id: service.id.toString(), name: service.name };
        }
      );
      setEPointClassesList(ClassList);

      const comments = schedulerVM.getCommentsGroupedByClassroom(
        schedulerResult
      );
      const schedulerMapped = schedulerVM.mapScheduler(schedulerResult);
      unstable_batchedUpdates(() => {
        setScheduler(schedulerResult);
        setMappedScheduler(schedulerMapped);
        setCommentsByClassroom(comments);
      });
    }

    setSitesDataLoaded(true);
    setServicesDataLoaded(true);
    setSchoolsDataLoaded(true);
    setClassesDataLoaded(true);
    dispatch(LoaderActions.setLoading(false));
  };

  const getSitesAndSelectOne = async (): Promise<IListItem> => {
    store.dispatch(AuthActions.setHavePointageAccount(true));
    const EPointageSites = await dispatch(
      EpointageThunks.getEpointageSites({})
    );

    const sitesResponse = _.get(EPointageSites, 'epointageSites') ?? [];

    const sitesList: IListItem[] = sitesResponse.map(
      (site: EpointageSite): IListItem => {
        return { id: site.id.toString(), name: site.label };
      }
    ).sort((a: IListItem, b: IListItem) => a.name.localeCompare(b.name));


    if (!selectedSite) {
      localStorage.setItem('siteId', JSON.stringify(sitesList[0]));
      setSelectedSite(sitesList[0])
    }
    setSitesList(sitesList);

    return selectedSite ?? sitesList[0];
  }
  const runResquests = () => {
    epointageRequests();
  };

  React.useEffect(() => {
    if (selectedSite) {
      runResquests()
    }
  }, [selectedSite, selectedSchool, selectedDate]);

  React.useEffect(() => {
    const updateAfterNewSiteSelected = (site: IListItem) => {
      localStorage.setItem('siteId', JSON.stringify(site));
      epointageRequests(site.id);
    };

    if (!selectedSite || !sitesList.length) {
      getSitesAndSelectOne().then((site: IListItem) => {
        updateAfterNewSiteSelected(site);
      });
      return;
    }
    updateAfterNewSiteSelected(selectedSite);
  }, [sitesList, selectedSite]);

  const runResquestsForClassesReset = () => {
    setSelectedClass(undefined);
  };

  React.useEffect(runResquestsForClassesReset, [selectedSchool]);

  const handleCommentsModal = () => {
    setIsModalOpen(true);
  };

  const handleDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const handleNextDay = () => {
    setSelectedDate(moment(selectedDate).add(1, "d"));
  };

  const handlePrevDay = () => {
    setSelectedDate(moment(selectedDate).subtract(1, "d"));
  };

  const [currentHeight] = useWindowSize();

  const styles = useMemo(() => _styles(currentHeight), [currentHeight]);

  const isLogin = useSelector((state: GlobalState) => state?.auth?.isLogin);

  const dataIsLoaded = () => {
    return sitesDataLoaded && classesDataLoaded && servicesDataLoaded && schoolsDataLoaded;
  };

  const school = I18n.t("epointage.homepage.school");
  const service = I18n.t("epointage.homepage.service");
  const class_ = I18n.t("epointage.homepage.allClasses");
  const select_school = I18n.t("epointage.homepage.infoBanner.selectSchool");
  const no_data = I18n.t("epointage.homepage.infoBanner.unavailableAttendance");

  const schoolIsOpenToday = !!scheduler && scheduler.school.isOpenToday;
  const pointageUnavailable =
    !selectedService ||
    (selectedSchool && selectedService && !schoolIsOpenToday);

  const handleSelection = (
    setItem: React.Dispatch<React.SetStateAction<IListItem | undefined>> | ((item: IListItem | undefined) => void)
  ) => (item: IListItem) => {
    setItem(item.id === "all" ? undefined : item);
  };

  const changeSelectedSite = (item: IListItem | undefined) => {
    setSelectedSite(item);
    setSelectedSchool(undefined);
    setSelectedClass(undefined);
    setSelectedService(undefined);
  }

  const HomePageContent: React.FC = () => (
    <View style={styles.contentContainer}>
      <View style={styles.contentHeader}>
        <Calendar
          date={selectedDate}
          nextDate={handleNextDay}
          prevDate={handlePrevDay}
        />
        <View style={styles.siteSelect}>
          <DropDownSelect
              type={'Sites'}
              selected={selectedSite?.name}
              colorize={!!selectedSite}
              list={sitesList}
              disabled={!dataIsLoaded()}
              selectIdHandler={handleSelection(changeSelectedSite)}
              isDynamic
              searchable={true}
            />
        </View>
      </View>
      <View style={styles.dropdownGroup}>
        <DropDownSelect
          type={service}
          selected={selectedService?.name}
          colorize={!!selectedService}
          list={ePointServicesList}
          disabled={!dataIsLoaded()}
          selectIdHandler={handleSelection(setSelectedService)}
          isDynamic
        />
        <DropDownSelect
          type={school}
          selected={selectedSchool?.name}
          colorize={!!selectedSchool}
          list={ePointSchoolsList}
          disabled={!dataIsLoaded()}
          selectIdHandler={handleSelection(setSelectedSchool)}
          isDynamic
        />
        <DropDownSelect
          type={class_}
          selected={selectedClass?.name}
          colorize={!!selectedClass}
          list={ePointClassesList}
          disabled={!selectedSchool}
          selectIdHandler={handleSelection(setSelectedClass)}
          allOptionsText={I18n.t("epointage.homepage.allClasses")}
          isDynamic
        />
        <Pressable style={styles.downloadIcon} onPress={handleDownloadModal}>
          <Download />
        </Pressable>
      </View>
      <View style={styles.infoContainer}>
        {!selectedSchool && selectedService && dataIsLoaded() && (
          <>
            <Title16 style={styles.infoText}>{select_school}</Title16>
            <SelectSchoolSVG />
          </>
        )}
        {pointageUnavailable && dataIsLoaded() && (
          <>
            <Title16 style={styles.infoText}>{no_data}</Title16>
            <PointageUnavailableSVG />
          </>
        )}
      </View>
      {selectedSite &&
        selectedSchool &&
        selectedService &&
        schoolIsOpenToday &&
        dataIsLoaded() && (
          <>
            <View style={styles.headerList}>
              <EpointageComments
                commentsByClassroom={commentsByClassroom}
                handleCommentsModal={handleCommentsModal}
                serviceId={parseInt(selectedService?.id)}
              />
              <EpointageSchedules
                scheduler={scheduler}
                serviceId={parseInt(selectedService?.id)}
                selectedDate={selectedDate.format("YYYY-MM-DD")}
              />
            </View>
            <EpointageClassrooms
              scheduler={mappedScheduler?.[`serviceId_${selectedService?.id}`]}
              mappedScheduler={mappedScheduler}
              serviceId={parseInt(selectedService?.id)}
              schoolId={selectedSchool?.id}
              siteId={selectedSite?.id}
              classroomId={selectedClass?.id}
              selectedDate={selectedDate.format("YYYY-MM-DD")}
              userId={scheduler?.user?.userId}
              userRole={scheduler?.user?.userRole}
              handleSchedulerInfo={epointageRequests}
            />
          </>
        )}
    </View>
  );

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <EpointageMenu />
        <EpointageScreenWithFooter navigation={navigation}>
          {isLogin && havePointageAccount ? (
            isLoading || !dataIsLoaded() ? (
              <View style={styles.loader}>
                <LocalLoader
                  containerStyle={{
                    backgroundColor: Colors.background2,
                  }}
                />
              </View>
            ) : (
              <HomePageContent />
            )
          ) : (
            <NoPointageAccount />
          )}

          <EpointageCommentModal
            commentsByClassroom={commentsByClassroom}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            selectedService={parseInt(selectedService?.id || "")}
          />
          {selectedSite?.id && <EpointageDownloadModal
            isDownloadModalOpen={isDownloadModalOpen}
            setIsDownloadModalOpen={setIsDownloadModalOpen}
            selectedSiteId={selectedSite.id}
          />}
        </EpointageScreenWithFooter>
      </View>
    </View>
  );
};

const getLocalStorageItemParsed = (key: string) => {
  const item = localStorage.getItem(key);

  if (item != null) {
    return JSON.parse(item);
  }

  return undefined;
}

const _styles = (currentHeight?: number) =>
  StyleSheet.create({
    mainContainer: {
      height: `${currentHeight}px`,
    },
    container: {
      flexDirection: "row",
      flex: 1,
    },
    contentContainer: {
      flexDirection: "column",
      display: "flex",
      flex: 1,
      alignItems: "center",
      padding: 34,
    },
    contentHeader: {
      flexDirection: "row",
      justifyContent: 'center',
      width: "100%",
      zIndex: 1,
    },
    infoContainer: {
      width: 363,
      alignItems: "center",
      zIndex: -1,
    },
    infoText: {
      marginBottom: 25,
      textAlign: "center",
    },
    dropdownGroup: {
      width: "100%",
      alignSelf: "flex-start",
      flexDirection: "row",
      flex: 1,
      justifyContent: "flex-start",
      columnGap: 20,
      flexGrow: 0,
      marginBottom: 80,
      zIndex: 1,
    },
    icon: {
      padding: 5,
      paddingLeft: 10,
      fontSize: 12,
    },
    dropdownContainer: {
      display: "flex",
      flexDirection: "column",
    },
    dropBoxContainer: {
      width: "100%",
      height: 100,
      borderColor: "#090909",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 5,
    },
    dropBoxSelect: {},
    loader: {
      height: "100%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    initialHeader: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      paddingHorizontal: 20,
      paddingTop: 30,
    },
    commentsWrapper: {
      flex: 0.5,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    headerList: {
      width: "100%",
      flexDirection: "row",
    },
    downloadIcon: {
      position: "absolute",
      right: 0,
      top: 6,
    },
    siteSelect: {
      position: 'absolute',
      right: 30
    }
  });
